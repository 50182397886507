import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './ClientDashboard.css';
import Modal from 'react-modal';

const { backend_url } = require('../config');

Modal.setAppElement('#root'); // Set the root element for the modal

const templates = [
    {
        name: 'rust_template',
        label: 'Rust Server Setup',
        fields: [
            { name: 'game_port', label: 'Game Port', value: 'port' },
            { name: 'query_port', label: 'Query Port', value: 'port' },
            { name: 'rcon_port', label: 'RCON Port', value: 'port' },
            { name: 'app_port', label: 'App Port', value: 'port' },
        ]
    },
    {
        name: 'anti_censys',
        label: 'Anti-Censys',
    },
    {
        name: 'minecraft',
        label: 'Minecraft Server',
        fields: [
            { name: 'server_port', label: 'Port', value: 'port' },
        ]
    },
    {
        name: 'web_server',
        label: 'Web Server',

    }
];

const filterOptions = [
    {
        name: 'ts3_server',
        label: 'TeamSpeak 3 Server',
        fields: [
            { name: 'voice_port', label: 'Voice Chat UDP Port', value: 'port' },
            { name: 'file_transfer_port', label: 'File transfer TCP Port', value: 'port' }
        ]
    },
    {
        name: 'openvpn_server',
        label: 'OpenVPN UDP Server',
        fields: [
            { name: 'port', label: 'UDP Port', value: 'port' }
        ]
    },
    {
        name: 'source_server',
        label: 'Source Engine Queries',
        fields: [
            { name: 'port', label: 'Port', value: 'port' },
            { name: 'strict', label: 'Strict Mode', value: 'bool' },
            { name: 'cache', label: 'A2S Caching', value: 'bool' },
            { name: 'override_port', label: 'Port override (Optional)', value: 'port' }
        ]
    },
    {
        name: 'raknet_server',
        label: 'RakNet Server (v2)',
        fields: [
            { name: 'port', label: 'Port', value: 'port' },
            { name: 'accept_queries', label: 'Accept server queries', value: 'bool' }
        ]
    },
    {
        name: 'tcp_service',
        label: 'TCP Service',
        fields: [
            { name: 'port', label: 'Port', value: 'port' }
        ]
    },
    {
        name: 'tcp_symmetric',
        label: 'TCP Service (symmetric)',
        fields: [
            { name: 'port', label: 'Port', value: 'port' },
            { name: 'max_conn_pps', label: 'Per-connection max packets per second', value: 'integer' }
        ]
    },
    {
        name: 'mc_server',
        label: 'Minecraft: Java Edition Server (symmetric)',
        fields: [
            { name: 'port', label: 'Port', value: 'port' }
        ]
    },
    {
        name: 'hl2_source_server',
        label: 'Half Life 2/Source Server',
        fields: [
            { name: 'port', label: 'Port', value: 'port' },
            { name: 'accept_queries', label: 'Accept server queries', value: 'bool' }
        ]
    },
    {
        name: 'fivem_server',
        label: 'GTA V Multiplayer Server (beta)',
        fields: [
            { name: 'port', label: 'UDP Port', value: 'port' },
            { name: 'tcp_port', label: 'TCP Port', value: 'port' },
            { name: 'strict', label: 'Strict Mode', value: 'bool' },
            { name: 'multi_ip_support', label: 'Multi-IP support', value: 'bool' }
        ]
    },
    {
        name: 'hlds_server',
        label: 'Half-Life Dedicated/GoldSrc Server',
        fields: [
            { name: 'port', label: 'Port', value: 'port' },
            { name: 'accept_queries', label: 'Accept server queries', value: 'bool' }
        ]
    },
    {
        name: 'dns_server',
        label: 'DNS Server',
        fields: [
            { name: 'port', label: 'Port', value: 'port' },
            { name: 'protocol', label: 'Layer 4 Protocol', value: 'select', options: ['udp', 'tcp'] }
        ]
    },
    {
        name: 'wireguard_server',
        label: 'WireGuard Server',
        fields: [
            { name: 'port', label: 'Port', value: 'port' }
        ]
    },
    {
        name: 'arma3_server',
        label: 'Arma 3 Server (beta)',
        fields: [
            { name: 'port', label: 'Port', value: 'port' },
            { name: 'accept_queries', label: 'Accept server queries', value: 'bool' }
        ]
    },
    {
        name: 'stun_server',
        label: 'STUN Server',
        fields: [
            { name: 'port', label: 'UDP Port', value: 'port' }
        ]
    },
    {
        name: 'samp_server',
        label: 'SA-MP Server Queries',
        fields: [
            { name: 'port', label: 'Port', value: 'port' }
        ]
    },
    {
        name: 'l4ds_server',
        label: 'L4D2/CS:GO Source',
        fields: [
            { name: 'port', label: 'Port', value: 'port' },
            { name: 'accept_queries', label: 'Accept server queries', value: 'bool' }
        ]
    },
    {
        name: 'raksamp_server',
        label: 'RakSAMP Filter',
        fields: [
            { name: 'port', label: 'Port', value: 'port' }
        ]
    },
    {
        name: 'quic_server',
        label: 'QUIC Server',
        fields: [
            { name: 'port', label: 'Port', value: 'port' }
        ]
    },
    {
        name: 'sip_server',
        label: 'SIP Server',
        fields: [
            { name: 'port', label: 'Port', value: 'port' },
            { name: 'protocol', label: 'Layer 4 Protocol', value: 'select', options: ['udp', 'tcp'] }
        ]
    },
    {
        name: 'dtls_server',
        label: 'DTLS Server',
        fields: [
            { name: 'port', label: 'Port', value: 'port' }
        ]
    },
    {
        name: 'rtp_server',
        label: 'RTP Server',
        fields: [
            { name: 'port_range', label: 'Ports', value: 'port_range' }
        ]
    },
    {
        name: 'rgx_server',
        label: 'Renegade X Server',
        fields: [
            { name: 'port', label: 'Port', value: 'port' },
            { name: 'accept_queries', label: 'Accept server queries', value: 'bool' }
        ]
    },
    {
        name: 'dayz_server',
        label: 'DayZ Server',
        fields: [
            { name: 'port', label: 'Port', value: 'port' },
            { name: 'accept_queries', label: 'Accept server queries', value: 'bool' }
        ]
    },
    {
        name: 'squad_server',
        label: 'Squad/Post Scriptum Server',
        fields: [
            { name: 'port', label: 'Port', value: 'port' }
        ]
    },
    {
        name: 'scp_sl_server',
        label: 'SCP: Secret Laboratory Server',
        fields: [
            { name: 'port', label: 'Port', value: 'port' },
            { name: 'accept_queries', label: 'Accept server queries', value: 'bool' },
            { name: 'preauth', label: 'Preauth', value: 'bool' }
        ]
    },
    {
        name: 'quake3_server',
        label: 'Quake 3 Server',
        fields: [
            { name: 'port', label: 'Port', value: 'port' }
        ]
    },
    {
        name: 'ase_server',
        label: 'ASE/Multi Theft Auto Queries',
        fields: [
            { name: 'port', label: 'Port', value: 'port' },
            { name: 'strict', label: 'Strict Mode', value: 'bool' }
        ]
    },
    {
        name: 'vrising_server',
        label: 'V Rising/ARK Server (DEPRECATED)',
        fields: [
            { name: 'port', label: 'Port', value: 'port' },
            { name: 'accept_queries', label: 'Accept server queries', value: 'bool' }
        ]
    },
    {
        name: 'litenet_server',
        label: 'LiteNetLib Server',
        fields: [
            { name: 'port', label: 'Port', value: 'port' },
            { name: 'accept_queries', label: 'Accept server queries', value: 'bool' }
        ]
    },
    {
        name: 'lineage2_server',
        label: 'Lineage II Server',
        fields: [
            { name: 'port', label: 'Game port', value: 'port' },
            { name: 'login_port', label: 'Login port', value: 'port' }
        ]
    },
    {
        name: 'steamworks_server',
        label: 'Steamworks Server',
        fields: [
            { name: 'port', label: 'Port', value: 'port' },
            { name: 'accept_queries', label: 'Accept server queries', value: 'bool' }
        ]
    },
    {
        name: 'fivem_cache_server',
        label: 'FiveM Server Queries',
        fields: [
            { name: 'port', label: 'Port', value: 'port' }
        ]
    },
    {
        name: 'palworld_server',
        label: 'Palworld Filter (beta)',
        fields: [
            { name: 'port', label: 'Port', value: 'port' }
        ]
    }
];

const ClientArea = () => {
    const [ips, setIps] = useState([]);
    const [selectedIp, setSelectedIp] = useState('');
    const [rules, setRules] = useState([]);
    const [filters, setFilters] = useState([]);
    const [presets, setPresets] = useState([]); // Added presets state
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalType, setModalType] = useState(''); // 'rule', 'filter', or 'preset'
    const [currentItem, setCurrentItem] = useState(null);
    const [newPreset, setNewPreset] = useState({
        name: '',
        settings: {}
    });

    const [newRule, setNewRule] = useState({
        ip: '',
        src_ip: '',
        dst_port: '',
        protocol: 'icmp',
        action: 'pass',
        note: ''
    });

    const [newFilter, setNewFilter] = useState({
        name: '',
        settings: {}
    });

    useEffect(() => {
        const fetchIps = async () => {
            const token = localStorage.getItem('token');
            try {
                const res = await axios.get(`${backend_url}/user/ips`, {
                    headers: {
                        Authorization: token
                    }
                });
                if (res.status === 403) {
                    window.location.href = '/unauthorized';
                }
                setIps(res.data);
            } catch (err) {
                console.error(err);
            }
        };

        fetchIps();
    }, []);

    const handleIpChange = async (e) => {
        setSelectedIp(e.target.value);
        const token = localStorage.getItem('token');
        try {
            const res = await axios.get(`${backend_url}/api/user/allocations?ip=${e.target.value}`, {
                headers: {
                    Authorization: token
                }
            });
            setRules(res.data.rules || []);
            setFilters(res.data.filters || []);
            setPresets(res.data.presets || []); // Set presets
        } catch (err) {
            console.error(err);
        }
    };

    const handleRuleDelete = async ({ ip, uuid }) => {
        const token = localStorage.getItem('token');
        try {
            await axios.delete(`${backend_url}/users/rules`, {
                headers: {
                    Authorization: token,
                    ip: ip,
                    uuid: uuid
                }
            });
            setRules(rules.filter(rule => rule.uuid !== uuid));
        } catch (err) {
            console.error(err);
            alert('Failed to delete the rule');
        }
    };

    const handleFilterDelete = async ({ ip, uuid }) => {
        const token = localStorage.getItem('token');
        try {
            await axios.delete(`${backend_url}/users/filters`, {
                headers: {
                    Authorization: token,
                    ip: ip,
                    uuid: uuid
                }
            });
            setFilters(filters.filter(filter => filter.uuid !== uuid));
        } catch (err) {
            console.error(err);
            alert('Failed to delete the filter');
        }
    };

    const handleRuleAdd = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');
        const ip = document.querySelector('select[name="ip"]').value;
        const ip_regex = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/;
        if (!ip_regex.test(ip)) {
            alert('Invalid IP address');
            return;
        }
        try {
            const res = await axios.post(`${backend_url}/users/rules`, newRule, {
                headers: {
                    Authorization: token,
                    ip: ip
                }
            });
            setRules([...rules, res.data]);
            setModalIsOpen(false);
            setNewRule({
                ip: ip,
                src_ip: '',
                src_port: '',
                protocol: 'icmp',
                action: 'pass',
                note: ''
            });

            // Force Refresh the page
            window.location.reload();
            // Set the ip to the previously selected ip
            setSelectedIp(ip);

        } catch (err) {
            console.error(err);
            alert('Failed to add the rule');
        }
    };

    const handlePresetAdd = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');
        const ip = selectedIp;
        try {
            const res = await axios.post(`${backend_url}/users/presets`, newPreset, {
                headers: {
                    Authorization: token,
                    ip: ip
                }
            });
            setPresets([...presets, res.data]);
            setModalIsOpen(false);
            setNewPreset({
                name: '',
                settings: {}
            });
            // Force Refresh the page
            window.location.reload();
            // Set the ip to the previously selected ip
            setSelectedIp(ip);

        } catch (err) {
            console.error(err);
            alert('Failed to add the preset');
        }
    };

    const handleFilterAdd = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');
        const ip = selectedIp;
        try {
            const res = await axios.post(`${backend_url}/users/filters`, newFilter, {
                headers: {
                    Authorization: token,
                    ip: ip
                }
            });
            setFilters([...filters, res.data]);
            setModalIsOpen(false);
            setNewFilter({
                name: '',
                settings: {}
            });

            // Force Refresh the page
            window.location.reload();
            // Set the ip to the previously selected ip
            setSelectedIp(ip);
        } catch (err) {
            console.error(err);
            alert('Failed to add the filter');
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewRule({
            ...newRule,
            [name]: value
        });
    };

    const handleFilterInputChange = (e) => {
        const { name, value } = e.target;
        setNewFilter({
            ...newFilter,
            settings: {
                ...newFilter.settings,
                [name]: value
            }
        });
    };

    const handlePresetInputChange = (e) => {
        const { name, value } = e.target;
        setNewPreset({
            ...newPreset,
            settings: {
                ...newPreset.settings,
                [name]: value
            }
        });
    };

    const renderFilterFields = () => {
        const selectedFilter = filterOptions.find(filter => filter.name === newFilter.name);
        if (!selectedFilter || !selectedFilter.fields) {
            return <p>No settings available for this filter</p>; // Handle empty or undefined fields gracefully
        }

        return selectedFilter.fields.map(field => {
            switch (field.value) {
                case 'port':
                case 'port_range':
                case 'integer':
                    return (
                        <div key={field.name} className="modal-field">
                            <label>{field.label}</label>
                            <input
                                type='number'
                                name={field.name}
                                placeholder={field.label}
                                value={newFilter.settings[field.name] || ''}
                                onChange={handleFilterInputChange}
                            />
                        </div>
                    );
                case 'bool':
                    return (
                        <div key={field.name} className="modal-field">
                            <label>{field.label}</label>
                            <select
                                name={field.name}
                                value={newFilter.settings[field.name] || 'false'}
                                onChange={handleFilterInputChange}
                            >
                                <option value='false'>False</option>
                                <option value='true'>True</option>
                            </select>
                        </div>
                    );
                case 'select':
                    return (
                        <div key={field.name} className="modal-field">
                            <label>{field.label}</label>
                            <select
                                name={field.name}
                                value={newFilter.settings[field.name] || ''}
                                onChange={handleFilterInputChange}
                            >
                                {field.options.map(option => (
                                    <option key={option} value={option}>{option}</option>
                                ))}
                            </select>
                        </div>
                    );
                default:
                    return null;
            }
        });
    };


    const renderFilterSettings = (filter) => {
        const selectedFilter = filterOptions.find(f => f.label === filter.name);
        if (!selectedFilter) return null;

        return selectedFilter.fields.map(field => {
            const key = Object.keys(filter.settings).find(k => k.toLowerCase() === field.label.toLowerCase()); // Case-insensitive match
            const value = filter.settings[key];

            const displayValue = (typeof value === 'boolean')
                ? (value ? 'True' : 'False')
                : (value !== undefined && value !== null)
                    ? value.toString()
                    : 'null';

            return (
                <p key={field.name}>
                    {field.label}: {displayValue}
                </p>
            );
        });
    };



    const renderPresetFields = () => {
        const selectedTemplate = templates.find(template => template.name === newPreset.name);

        // Check if the selected template has fields before calling map
        if (!selectedTemplate) {
            return <p>No template selected</p>; // Handle case where no template is selected
        }

        if (!selectedTemplate.fields || selectedTemplate.fields.length === 0) {
            return <p>No fields available for this template</p>; // Handle case where template has no fields
        }

        return selectedTemplate.fields.map(field => (
            <div key={field.name} className="modal-field">
                <label>{field.label}</label>
                <input
                    className='modal-input'
                    type='text'
                    name={field.name}
                    placeholder={field.label}
                    value={newPreset.settings[field.name] || ''}
                    onChange={handlePresetInputChange}
                />
            </div>
        ));
    };


    return (
        <div>
            <header>
                <h1 className='header'>Client Area</h1>
            </header>
            <div className='search'>
                <select onChange={handleIpChange} value={selectedIp}>
                    <option value="" disabled>Select IP Address</option>
                    {ips.map(ip => (
                        <option key={ip} value={ip}>{ip}</option>
                    ))}
                </select>
            </div>
            {selectedIp && (
                <>
                    <div className='table-container'>
                        <h2>Rules</h2>
                        <button onClick={() => { setModalType('rule'); setModalIsOpen(true); }}>Add Rule</button>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>Notes</th>
                                    <th>Action</th>
                                    <th>Protocol</th>
                                    <th>Source</th>
                                    <th>Destination</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rules.length === 0 ? (
                                    <tr>
                                        <td colSpan="6">No rules available</td>
                                    </tr>
                                ) : (
                                    rules.map(rule => (
                                        <tr key={rule.uuid}>
                                            <td>{rule.note}</td>
                                            <td>{rule.action === 'drop' ? 'BLOCK' : 'ALLOW'}</td>
                                            <td className='upper'>{rule.protocol}</td>
                                            <td>{rule.src_ip === '0' || rule.src_ip === 0 ? 'All IPs' : rule.src_ip}</td>
                                            <td>{rule.dst_port === '0' || rule.dst_port === 0 ? 'All Ports' : rule.dst_port}</td>
                                            <td>
                                                <button className='delete-box' onClick={() => handleRuleDelete({
                                                    ip: selectedIp,
                                                    uuid: rule.uuid
                                                })}>X</button>
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className='table-container'>
                        <h2>Filters</h2>
                        <button onClick={() => { setModalType('filter'); setModalIsOpen(true); }}>Add Filter</button>
                        <button onClick={() => { setModalType('preset'); setModalIsOpen(true); }}>Add Preset</button>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Settings</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filters.length === 0 ? (
                                    <tr>
                                        <td colSpan="3">No filters available</td>
                                    </tr>
                                ) : (
                                    filters.map(filter => (
                                        <tr key={filter.uuid}>
                                            <td>{filter.name}</td>
                                            <td>{renderFilterSettings(filter)}</td>
                                            <td>
                                                <button className='delete-box' onClick={() => handleFilterDelete({
                                                    ip: selectedIp,
                                                    uuid: filter.uuid
                                                })}>X</button>
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                    </div>
                </>
            )}
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                className="Modal"
                overlayClassName="Overlay"
            >
                {modalType === 'rule' ? (
                    <div className='modal'>
                        <h2>Add Rule</h2>
                        <form onSubmit={handleRuleAdd}>
                            <select name='ip' value={newRule.ip} onChange={handleInputChange}>
                                <option value={selectedIp}>{selectedIp}</option>
                            </select>
                            <input
                                type='text'
                                name='src_ip'
                                placeholder='Source IP (Blank for all IPs)'
                                value={newRule.src_ip}
                                onChange={handleInputChange}
                            />
                            <input
                                type='text'
                                name='dst_port'
                                placeholder='Destination Port (0 for all ports)'
                                value={newRule.dst_port}
                                onChange={handleInputChange}
                            />
                            <select name='protocol' value={newRule.protocol} onChange={handleInputChange}>
                                <option value='icmp'>ICMP</option>
                                <option value='gre'>GRE</option>
                                <option value='tcp'>TCP</option>
                                <option value='udp'>UDP</option>
                            </select>
                            <select name='action' value={newRule.action} onChange={handleInputChange}>
                                <option value='pass'>ALLOW</option>
                                <option value='drop'>BLOCK</option>
                            </select>
                            <input
                                type='text'
                                name='note'
                                placeholder='Note'
                                value={newRule.note}
                                onChange={handleInputChange}
                            />
                            <button type='submit'>Add Rule</button>
                        </form>
                    </div>
                ) : modalType === 'filter' ? (
                    <div className='modal'>
                        <h2>Add Filter</h2>
                        <form onSubmit={handleFilterAdd}>
                            <select name='name' value={newFilter.name} onChange={(e) => setNewFilter({ ...newFilter, name: e.target.value, settings: {} })}>
                                <option value='' disabled>Select Filter</option>
                                {filterOptions.map(option => (
                                    <option key={option.name} value={option.name}>{option.label}</option>
                                ))}
                            </select>
                            {newFilter.name && renderFilterFields()}
                            <button type='submit'>Add Filter</button>
                        </form>
                    </div>
                ) : (
                    <div className='modal'>
                        <h2>Add Preset</h2>
                        <form onSubmit={handlePresetAdd}>
                            <select name='name' value={newPreset.name} onChange={(e) => setNewPreset({ ...newPreset, name: e.target.value, settings: {} })}>
                                <option value='' disabled>Select Preset</option>
                                {templates.map(template => (
                                    <option key={template.name} value={template.name}>{template.label}</option>
                                ))}
                            </select>
                            {newPreset.name && renderPresetFields()}
                            <button type='submit'>Add Preset</button>
                        </form>
                    </div>
                )}
            </Modal>
        </div>
    );
};

export default ClientArea;
