import React, { useState } from 'react';
import axios from 'axios';
import QRCode from 'react-qr-code';  // Import react-qr-code
import './Profile.css';  // Import CSS file for modal styling
const { backend_url } = require("../config");

const Profile = () => {
    const token = localStorage.getItem('token');
    const [showModal, setShowModal] = useState(false);  // State to control modal visibility
    const [otpCode, setOtpCode] = useState('');  // State to store the OTP code
    const [message, setMessage] = useState('');
    const [passwordMessage, setPasswordMessage] = useState('');

    // Password reset functionality
    const resetPassword = async (e) => {
        e.preventDefault();
        const currentPassword = e.target.currentPassword.value;
        const newPassword = e.target.newPassword.value;
        const confirmPassword = e.target.confirmPassword.value;

        if (newPassword !== confirmPassword) {
            setPasswordMessage('Passwords do not match');
            return;
        }

        try {
            const response = await axios.post(`${backend_url}/users/update_password`, {
                currentPassword,
                newPassword,
            }, {
                headers: {
                    Authorization: `${token}`,
                },
            });
            setPasswordMessage('Password changed successfully');
        } catch (error) {
            console.error('Error updating password:', error);
            const pass_msg = document.querySelector('.pass-msg');
            pass_msg.style.color = 'red';
            
            setPasswordMessage('Failed to update password. Please try again.');
        }
    };

    return (
        <div>
            <h1>Profile</h1>
            <div className='security-area'>
                <h3>Reset Password</h3>
                <form onSubmit={resetPassword}>
                    <label>
                        Current Password:
                        <input type="password" name="currentPassword" required />
                    </label>
                    <label>
                        New Password:
                        <input type="password" name="newPassword" required />
                    </label>
                    <label>
                        Confirm New Password:
                        <input type="password" name="confirmPassword" required />
                    </label>
                    <button type="submit">Change Password</button>
                </form>
                {passwordMessage && <p className='pass-msg'>{passwordMessage}</p>}
            </div>

        </div>
    );
};

export default Profile;
