import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
const { backend_url } = require('../config');

const Protected = () => {
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProtectedData = async () => {
            const token = localStorage.getItem('token');
            if (!token) {
                navigate('/login');
                return;
            }

            try {
                const res = await axios.get(`${backend_url}/protected`, {
                    headers: {
                        Authorization: token
                    }
                });
                setMessage(res.data);
            } catch (err) {
                console.error(err);
                setMessage('Failed to fetch protected data');
            }
        };

        fetchProtectedData();
    }, [navigate]);

    return (
        <div>
            <h1>Protected Page</h1>
            <p>{message}</p>
        </div>
    );
};

export default Protected;
