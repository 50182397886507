
import Modal from 'react-modal';
import './UserRegistration.css';
import axios from 'axios';

const { backend_url } = require('../config');
const UserRegistration = () => {
    Modal.setAppElement('#root'); // Set the root element for the modal

    const handleRegister = async (e) => {
        e.preventDefault();
        const username = document.getElementById('username').value;
        const password = document.getElementById('password').value;
        const email = document.getElementById('email').value;
        try {
            const res = await axios.post(`${backend_url}/admin/register`, {
                username,
                password,
                email
            },
            {
                headers: {
                    'Authorization': localStorage.getItem('token')
                }
            });
            document.querySelector('.user-registration__message').innerHTML = res.data;
        } catch (err) {
            console.error(err);
            document.querySelector('.user-registration__message').innerHTML = 'Failed to register user';
        }
    }
    return (
        <div className='user-registration'>
            <h1>User Registration</h1>
            <div className='reg-form'>
                <form>
                    <input type='text' id='username' name='username' placeholder='username' />
                    <input type='password' id='password' name='password' placeholder='password' />
                    <input type='email' id='email' name='email' placeholder='Email Address'/>
                    <button type='submit' onClick={handleRegister}>Register</button>
                </form>
            </div>
            <div className='user-registration__message'></div>
        </div>
    );
}

export default UserRegistration;
