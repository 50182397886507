// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import Protected from './components/Protected';
import ClientArea from './components/ClientDashboard';
import Admin from './components/Admin';
import UserManagement from './AdminArea/UserManagement';
import UserRegistration from './AdminArea/UserRegistration';
import Unauthorized from './components/Unauthorized';
import { AuthProvider } from './context/AuthContext';
import Navbar from './components/Navbar';
import { ProtectedRoute } from './utils/auth';
import PublicRoute from './utils/PublicRoute';

import './App.css';
import PasswordReset from './components/PasswordReset';
import Profile from './components/Profile';

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Navbar />
        <div className={`App darkmode`}>
          <Routes>

            {/* Public Routes */}
            <Route path="/" element={<PublicRoute><Login /></PublicRoute>} />
            <Route path="/login" element={<PublicRoute><Login /></PublicRoute>} />
            <Route path="/unauthorized" element={<Unauthorized />} />
            <Route path='*' element={<PublicRoute><Login /></PublicRoute>} />
            <Route path='/user/reset-password' element={<PublicRoute><PasswordReset /></PublicRoute>} />
            <Route path='/users/passwordreset/success' element={<PublicRoute><PasswordReset /></PublicRoute>} />

            
            {/* Admin Area */}
            <Route path="/admin/ip-lookup" element={<ProtectedRoute role="admin"><Admin /></ProtectedRoute>} />
            <Route path="/admin/register" element={<ProtectedRoute role="admin"><UserRegistration /></ProtectedRoute>} />
            <Route path="/admin/user-management" element={<ProtectedRoute role="admin"><UserManagement /></ProtectedRoute>} />


            {/* Client Routes */}
            <Route path="/dashboard" element={<ProtectedRoute><ClientArea /></ProtectedRoute>} />
            <Route path='/profile' element={<ProtectedRoute><Profile /></ProtectedRoute>} />
            <Route path="/dashboard" element={<ProtectedRoute><ClientArea /></ProtectedRoute>} />
            <Route path='/profile' element={<ProtectedRoute><Profile /></ProtectedRoute>} />

          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
};

export default App;
