import axios from "axios";
import './PasswordReset.css';
const { useState } = require("react");
const { backend_url } = require("../config");

const PasswordReset = () => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);

    const onSubmit = async (e) => {
        e.preventDefault();

        try {
            await axios.post(`${backend_url}/reset-password`, {
                email,
            });

            // If the request is successful, display a success message
            document.getElementById('message').style.color = 'green';
            document.getElementById('message').innerHTML = 'Password reset email sent successfully';
            setEmail('');

            // Wait 1 second and redirect to login page
            setTimeout(() => {
                window.location.href = '/login';
            }, 1000);

        } catch (error) {
            setError(error);
            document.getElementById('message').style.color = 'red';
        }
    };

    return (
        <div className="password-reset">
            <h1>Password Reset</h1>
            <form onSubmit={onSubmit}>
                <input
                    type="email"
                    placeholder="Email"
                    onChange={(e) => setEmail(e.target.value)}
                />
                <button type="submit">Reset Password</button>
            </form>
            <div className="message" id="message">
                {error && <p>{error.message}</p>}
            </div>
        </div>
    );
};

export default PasswordReset;
