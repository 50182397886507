// utils/auth.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode';

export const getToken = () => {
    return localStorage.getItem('token'); // Or use cookies for better security
};

export const getUserRole = () => {
    const token = getToken();
    if (!token) return null;

    try {
        const payload = jwtDecode(token);
        return payload.role;
    } catch (error) {
        return null;
    }
};

export const ProtectedRoute = ({ children, role }) => {
    const token = getToken();
    const userRole = getUserRole();

    if (!token) {
        return <Navigate to="/login" />;
    }

    if (role && role !== userRole) {
        return <Navigate to="/unauthorized" />;
    }

    return children;
};
