import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import './UserManagement.css'; // Import the CSS file

const { backend_url } = require('../config');

const UserManagement = () => {
    const [users, setUsers] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);

    useEffect(() => {
        const fetchUsers = async () => {
            const token = localStorage.getItem('token');
            try {
                const res = await axios.get(`${backend_url}/admin/users`, {
                    headers: {
                        Authorization: `${token}`
                    }
                });
                setUsers(res.data);
            } catch (err) {
                console.error(err);
            }
        };

        fetchUsers();
    }, []);

    const handleDelete = async (username) => {
        const token = localStorage.getItem('token');
        try {
            await axios.delete(`${backend_url}/admin/user/${username}`, {
                headers: {
                    Authorization: `${token}`
                }
            });
            setUsers(users.filter(user => user.username !== username));
        } catch (err) {
            console.error(err);
            alert('Failed to delete the user');
        }
    };

    const handleEdit = (user) => {
        setCurrentUser(user);
        setModalIsOpen(true);
    };

    const handleEditModel = async (e) => {
        if (e) e.preventDefault();
        const token = localStorage.getItem('token');
        const username = document.getElementById('username').value;
        const email = document.getElementById('email').value;
        const role = document.getElementById('role').value;
        const delIP = document.getElementById('delIP').value;
        const addIP = document.getElementById('addIP').value;

        if (delIP) {
            try {
                await axios.post(`${backend_url}/admin/user/del-ip`, {
                    username: username,
                    ip: delIP
                }, {
                    headers: {
                        Authorization: token
                    }
                });


            } catch (err) {
                console.error(err);
                alert('Failed to delete IP');
            }

            // If Successful, update the user's IPs
            const user = users.find(user => user.username === username);
            const updatedUser = { ...user };
            updatedUser.ips = updatedUser.ips.filter(ip => ip !== delIP);
            setUsers(users.map(user => user.username === username ? updatedUser : user));

            // Add Success Message
            document.querySelector('.user-registration__message').innerHTML = 'IP deleted successfully';
            document.getElementById('delIP').value = '';

            document.querySelector('.user-registration__message').style.color = 'green';
        }

        if (addIP) {
            try {
                await axios.post(`${backend_url}/admin/user/add-ip`, {
                    username: username,
                    ip: addIP
                }, {
                    headers: {
                        Authorization: token
                    }
                });
            } catch (err) {
                console.error(err);
                alert('Failed to add IP');
            }
        
            
            // If Successful, update the user's IPs
            const user = users.find(user => user.username === username);
            const updatedUser = { ...user };
            updatedUser.ips.push(addIP);
            setUsers(users.map(user => user.username === username ? updatedUser : user));

            // Add Success Message

            document.querySelector('.user-registration__message').innerHTML = 'IP added successfully';
            document.getElementById('addIP').value = '';
            document.querySelector('.user-registration__message').style.color = 'green';
        }


        
    }
    return (
        <div>
            <h1>User Management</h1>
            <table>
                <thead>
                    <tr>
                        <th>Username</th>
                        <th>Email</th>
                        <th>Role</th>
                        <th>User IPs</th>
                        <th>Created</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map(user => (
                        <tr key={user.username}>
                            <td>{user.username}</td>
                            <td>{user.email}</td>
                            <td>{user.role}</td>
                            <td>{user.ips.join(', ') || 'None'}</td>
                            <td>{user.createdAt}</td>
                            <td>
                                <button className="edit" onClick={() => handleEdit(user)}>Edit</button>
                                <button className="delete" onClick={() => handleDelete(user.username)}>Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                contentLabel='Edit User'
                className='Modal'
                overlayClassName='Overlay'
            >
                <h2>Edit User</h2>
                <form>
                    <label htmlFor='username'>Username</label>
                    <input type='text' id='username' name='username' placeholder='Username' value={currentUser?.username} />
                    <label htmlFor='email'>Email</label>
                    <input type='email' id='email' name='email' placeholder='Email Address' value={currentUser?.email} />
                    <label htmlFor='delIP'>Remove IP</label>
                    <select id='delIP' name='delIP'>
                        <option value=''>Select IP to remove</option>
                        {currentUser?.ips.map(ip => (
                            <option key={ip} value={ip}>{ip}</option>
                        ))}
                    </select>
                    <label htmlFor='addIP'>Add IP</label>
                    <input type='text' id='addIP' name='addIP' placeholder='Add IP' />
                    <label htmlFor='role'>Role</label>
                
                    <select id='role' name='role' value={currentUser?.role}>
                        <option value='admin'>Admin</option>
                        <option value='user'>User</option>
                    </select>
                    <button type='submit' onClick={handleEditModel}>Save</button>
                    <div className='user-registration__message'></div>
                </form>
            </Modal>
        </div>
    );

}

export default UserManagement;
