import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Admin.css'
import Modal from 'react-modal';
const { backend_url } = require('../config');
Modal.setAppElement('#root'); // Set the root element for the modal

const Protected = () => {
    const [message, setMessage] = useState({});
    const [rules, setRules] = useState([]);
    const [filters, setFilters] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);

    const handleSubmit = async () => {
        const ip = document.getElementById('ip').value;
        const token = localStorage.getItem('token');
        try {
            const res = await axios.get(`${backend_url}/admin/allocations?ip=${ip}`, {
                headers: {
                    Authorization: token
                }
            });
            setMessage(res.data);
        } catch (err) {
            console.error(err);
            setMessage('Failed to fetch protected data');
        }
    };

    const handleInfo = (item) => {
        setCurrentItem(item);
        setModalIsOpen(true);
    };

    const handleDelete = async (uuid) => {
        const token = localStorage.getItem('token');
        try {
            await axios.delete(`${backend_url}/admin/rule/${uuid}`, {
                headers: {
                    Authorization: token
                }
            });
            setRules(rules.filter(rule => rule.uuid !== uuid));
        } catch (err) {
            console.error(err);
            alert('Failed to delete the rule');
        }
    };

    useEffect(() => {
        if (message.rules) {
            setRules(message.rules);
        }
        if (message.filters) {
            setFilters(message.filters);
        }
    }, [message]);

    const handleSort = (e) => {
        const sortedRules = [...rules];
        if (e.target.value === 'dst_port') {
            sortedRules.forEach(rule => {
                if (rule.dst_port === null) {
                    rule.dst_port = 0;
                }
            });
            sortedRules.sort((a, b) => a.dst_port - b.dst_port);
        } else if (e.target.value === 'proto') {
            sortedRules.forEach(rule => {
                if (rule.protocol === null) {
                    rule.protocol = '';
                }
            });
            sortedRules.sort((a, b) => a.protocol.localeCompare(b.protocol));
        } else if (e.target.value === 'src_ip') {
            sortedRules.forEach(rule => {
                if (rule.src_ip === null) {
                    rule.src_ip = '';
                }
            });
            sortedRules.sort((a, b) => a.src_ip.localeCompare(b.src_ip));
        } else if (e.target.value === 'src_port') {
            sortedRules.forEach(rule => {
                if (rule.src_port === null) {
                    rule.src_port = 0;
                }
            });
            sortedRules.sort((a, b) => a.src_port - b.src_port);
        }

        setRules(sortedRules);
    };

    return (
        <div>
            <header>
                <h1 className='header'>Firewall Rules</h1>
            </header>
            <div className='search'>
                <input type="text" id="ip" placeholder="Enter IP Address" />
                <button id="submit" onClick={handleSubmit}>Submit</button>
                <select onChange={handleSort}>
                    <option value="dst_port">Destination Port</option>
                    <option value="proto">Protocol</option>
                    <option value="src_ip">Source IP</option>
                    <option value="src_port">Source Port</option>
                </select>
            </div>
            <div className='rule-header'>
                <div className='col1'>Notes</div>
                <div className='col2'>Proto</div>
                <div className='col3'>Source</div>
                <div className='col4'>Destination</div>
                <div className='col-btn'>Action</div>
            </div>
            <div className='rules' id='rules-container'>
                {rules.length === 0 ? (
                    <p>No rules available</p>
                ) : (
                    rules.map(rule => (
                        <div key={rule.uuid} className='rule'>
                            <div className='col1'>
                                <p>Notes: {rule.note}</p>
                                <p>UUID: {rule.uuid}</p>
                            </div>
                            <div className='col2'>
                                <p>Protocol: {rule.protocol}</p>
                            </div>
                            <div className='col3'>
                                <p>Source IP: {rule.src_ip}</p>
                                <p>Source Port: {rule.src_port}</p>
                            </div>
                            <div className='col4'>
                                <p>Destination Port: {rule.dst_port}</p>
                            </div>
                            <div className='col-btn'>
                                <button className='info-box' onClick={() => handleInfo(rule)}>Info</button>
                                <button className='delete-box' onClick={() => handleDelete(rule.uuid)}>Delete</button>
                            </div>
                        </div>
                    ))
                )}
            </div>
            <div className='filter-header'>
                <div className='col1'>Name</div>
                <div className='col2'>Settings</div>
                <div className='col3'>Details</div>
                <div className='col-btn'>Action</div>
            </div>
            <div className='rules' id='filters-container'>
                {filters.length === 0 ? (
                    <p>No filters available</p>
                ) : (
                    filters.map(filter => (
                        <div key={filter.uuid} className='rule'>
                            <div className='col1'>
                                <p>Name: {filter.name}</p>
                            </div>
                            <div className='col2'>
                                <p>Port: {filter.settings.Port}</p>
                            </div>
                            <div className='col3'>
                                {filter.settings['A2S Caching'] !== undefined && <p>A2S Caching: {filter.settings['A2S Caching'] ? 'Yes' : 'No'}</p>}
                                {filter.settings['Strict Mode'] !== undefined && <p>Strict Mode: {filter.settings['Strict Mode'] ? 'Yes' : 'No'}</p>}
                                {filter.settings['Port override'] !== undefined && <p>Port Override: {filter.settings['Port override']? `Port Override ${filter.settings['Port verride']}`: 'Not Set'}</p>}
                                {filter.settings['Per-connection max packets per second'] !== undefined && <p>Max Packets/Sec: {filter.settings['Per-connection max packets per second']}</p>}
                            </div>
                            <div className='col-btn'>
                                <button className='info-box' onClick={() => handleInfo(filter)}>Info</button>
                                <button className='delete-box' onClick={() => handleDelete(filter.uuid)}>Delete</button>
                            </div>
                        </div>
                    ))
                )}
            </div>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                contentLabel="Info Modal"
                className="Modal"
                overlayClassName="Overlay"
            >
                {currentItem && (
                    <div>
                        <h2>Details</h2>
                        <pre>{JSON.stringify(currentItem, null, 2)}</pre>
                        <button onClick={() => setModalIsOpen(false)}>Close</button>
                    </div>
                )}
            </Modal>
        </div>
    );

};

export default Protected;
