// components/Navbar.js
import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import logo from '../Assets/logo.png'; // Import the logo
import './Navbar.css'; // Import the CSS

const Navbar = () => {
    const { user, logout } = useAuth();

    return (
        <nav className="navbar">
            <div className="navbar-logo">
                <Link to="/"><img src={logo} alt="Logo" /></Link>
            </div>
            <ul className="navbar-links">
                <li><Link to="/">Home</Link></li>
                {user && user.role === 'admin' && (
                    <>
                        <li><Link to="/admin/register">Register User</Link></li>
                        <li><Link to="/admin/ip-lookup">IP Lookup</Link></li>
                        <li><Link to="/admin/user-management">User Management</Link></li>
                        <li><Link to='/profile'>Profile</Link></li>
                    </>
                )}
                {user && user.role === 'user' && (
                    <>
                        <li><Link to="/firewall-manager">Firewall Manager</Link></li>
                        <li><Link to="/profile">Profile</Link></li>
                    </>
                )}
                {user ? (
                    <li><button onClick={logout} className="navbar-logout">Logout</button></li>
                ) : (
                    <li><Link to="/login">Login</Link></li>
                )}
            </ul>
        </nav>
    );
};

export default Navbar;
